import React from "react"
import { useLocation, useParams } from "react-router-dom"

const SecondPage = () => {
  const data = useLocation()
  const params = useParams()
  console.log(data)
  console.log(params)
  return <div>Welcome to the second page</div>
}

export default SecondPage

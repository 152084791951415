import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BotnavProvider } from "../contexts/BotnavContext";

const BotnavContainer = ({ children }) => {
  const [menuSelected, setIsMenuSelected] = useState(0);
  const navigate = useNavigate()
  const setMenuItem = (newValue) => {
      setIsMenuSelected(newValue)
      navigate('/')
  }

  return (
    <BotnavProvider
      value={{
        menuSelected,
        setMenuItem
      }}
    >
      {children}
    </BotnavProvider>
  );
};

export default BotnavContainer;

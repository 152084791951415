import { Dialog, Divider, Grid, Typography } from "@mui/material";
import CallPhone from "@mui/icons-material/Call";
import React from "react";
import Pin from "@mui/icons-material/LocationOn";

const LocationDialogue = ({
  open,
  handleClose,
  vendorName,
  locationData,
  selectedLocation,
  switchLocation,
}) => {
  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"lg"}>
      <Grid container className="locationDialogueContainer">
        <Grid item xs={12} className="locationDialogueHeaderContainer">
          <h4 className="locationDialogueTitle">{vendorName}</h4>
          <button className="locationCloseButton" onClick={handleClose}>
            Cancel
          </button>
        </Grid>
        <Grid item xs={12} className="locationOutletCount">
          <Grid containter className="locationOutletCountFlex">
            <Grid item xs={1}>
              <Pin />
            </Grid>
            <Grid item xs={3}>
              {locationData.length} Outlets
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="locationDialogueBodyContainer">
          <Grid container className="categoryDialogueListContainer">
            {locationData &&
              locationData.map((location) => (
                <LocationElement
                  vendorId={location.sp_ID}
                  key={location.sp_ID}
                  selectedLocation={selectedLocation}
                  vendorLocality={location.locality}
                  switchLocation={switchLocation}
                  handleClose={handleClose}
                />
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
function LocationElement(props) {
  const handleLocationClick = (vendorId, selectedId) => {
    if (selectedId !== vendorId) {
      props.switchLocation(vendorId);
      props.handleClose()
    }
  };
  return (
    <React.Fragment>
      <Grid item xs={2} className="locationPinIcon">
        <Pin />
      </Grid>
      <Grid
        item
        xs={10}
        className="dialogueCallText"
        onClick={() =>
          handleLocationClick(props.vendorId, props.selectedLocation.sp_ID)
        }
      >
        {props.vendorLocality} <br />
        <small>{props.city}</small>
      </Grid>
      <Grid item xs={12} className="callListDivider">
        <Divider />
      </Grid>
    </React.Fragment>
  );
}
export default LocationDialogue;

import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import VendorList from "../../components/vendorlist/VendorList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ categoryClicked, category }) {
  const [value, setValue] = React.useState(0);
  const ot = category && category.offers_included;
  // console.log(ot);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="vendorListPageContainer">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
          className="vendorListTab"
        >
          {ot &&
            ot.map((offer, i) => (
              <Tab
                label={offer.name}
                {...a11yProps(i)}
                key={i}
              />
            ))}
        </Tabs>
      </Box>
      {ot &&
        ot.map((offer, i) => (
          <TabPanel value={value} index={i} key={i} >
            <VendorList offertypeToShow={offer.ot_id} categoryClicked={categoryClicked} />
          </TabPanel>
        ))}
    </Box>
  );
}

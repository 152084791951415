import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MAINAPIS from "../../../helper/apiCalls";
import VendorListElementSkeleton from "../skeletons/VendorListElementSkeleton";
import VendorListElement from "./VendorListElement";
import VendorListSearch from "./VendorListSearch";
const VendorList = ({ offertypeToShow, categoryClicked }) => {
  const params = useParams();
  // States
  const [vendorList, setVendorList] = useState(() => []);
  const [vendorSearchResult, setVendorSearchResult] = useState(() => []);
  const [isLoading, setIsLoading] = useState(false);
  // Effects
  useEffect(async () => {
    const { data: vendorData = [] } = await getVendorList();
    setVendorList(vendorData);
    setVendorSearchResult(vendorData);
  }, []);
  // Fetching Vendor List to set State
  const getVendorList = async () => {
    try {
      // console.log(offertypeToShow)
      const result = await MAINAPIS.getVendorList(
        params.user,
        params.city,
        categoryClicked,
        offertypeToShow
      );
      setIsLoading(true);
      return result;
    } catch (error) {
      return [];
    }
  };
  function handleSearchChange(event) {
    // if (event.keyCode === 13) {
    const keywordToSearch = event.target.value;
    const keyword = keywordToSearch.toLowerCase();
    setVendorSearchResult(filterSearchResult(keyword, vendorList));
    // }
  }
  function filterSearchResult(keyword, arrayToFilter) {
    //search keyword from names array by name
    var searchResult = arrayToFilter.filter(
      (word) => word.name.toLowerCase().indexOf(keyword) > -1
    );
    return searchResult;
  }
  return (
    <React.Fragment>
      <VendorListSearch onSearch={handleSearchChange} />
      {isLoading
        ? vendorSearchResult.map((vendor, i) => (
            <VendorListElement key={i} vendor={vendor} userId={params.user} offer={offertypeToShow} />
          ))
        : [1, 2, 3, 4, 5, 6, 7].map((n) => (
            <VendorListElementSkeleton key={n} />
          ))}
    </React.Fragment>
  );
};

export default VendorList;

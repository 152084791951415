import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { Paper, Grid } from "@mui/material";
import Savings from "@mui/icons-material/Savings";

export default class BottomCarousel2 extends Component {
  render() {
    const {slide: slideData = []} = this.props;

    // var showNewSlider = false
    // if (this.props.type === "new")
    //   showNewSlider = true;
    // const { new: newdata = [], featured = [] } = slideData;
    return (
      <Carousel
        autoPlay={false}
        emulateTouch={true}
        showArrows={true}
        showIndicators={false}
        swipeScrollTolerance={50}
        showThumbs={false}
        showStatus={false}
        className="demo-carousel"
      >
        {
          (
            slideData.map((slide, i) => ( <BottomSlide key={i} slide={slide} /> ))
          )
        }
      </Carousel>
    );
  }
}
function BottomSlide({ slide }) {
  const customCSS = {
    backgroundColor: "black",
    color: "white",
    PointerEvent: "none",
    // minHeight: "30vh"
  };
  return (
    <div style={customCSS}>
      <Link to={"/second-page"}>
        <img
          draggable="false"
          src={`https://hbl2server.vouch365.xyz/${slide.header}`}
          alt=""
        />
      </Link>
    </div>
  );
}

import React from "react";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import NavigationDrawer from "../../../app-bar/NavigationDrawer";
import BackArrow from "@mui/icons-material/ArrowBackIosNew";
import { Link, useParams } from "react-router-dom";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { FavIcon } from "./FavIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    paddingTop: "10% !important",
    paddingBottom: 0,
  },
  subTitle: {
    flexGrow: 1,
    paddingTop: "1% !important",
    paddingBottom: 0,
  },
  backLink: {
    color: "#fff",
  },
  middleGridContainer: {
    height: "100%",
    width: "100%",
  },
}));

export default function VendorPageNav({ tabLocation, handleTabChange, favCheck, title, categoryID, cityName, userID }) {
  const classes = useStyles();
  const params = useParams();
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <div className={classes.root}>
      <NavigationDrawer />
      <AppBar position="fixed" color="secondary">
        <Toolbar>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={2}>
                  <Grid
                    container
                    className={classes.middleGridContainer}
                    justifyContent="flex-start"
                    alignContent="center"
                  >
                    <Grid item>
                      <Link to={`/vendorlist/${categoryID}/${userID}/${cityName}`} className={"basicLink"}>
                        <BackArrow />
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <Grid
                    container
                    className={classes.middleGridContainer}
                    justifyContent="center"
                  >
                    <Grid item>
                      <Typography
                        variant="h5"
                        className={classes.title}
                        sx={{
                          textTransform: "capitalize",
                          textAlign: "center",
                          p: 2,
                        }}
                      >
                        {title}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid
                    container
                    className={classes.middleGridContainer}
                    justifyContent="flex-end"
                    alignContent="center"
                  >
                    <Grid item>
                    {/* <FavIcon favCheck={favCheck} /> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabLocation}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                  variant="fullWidth"
                >
                  <Tab label="Offers" {...a11yProps(0)} />
                  <Tab label="Details" {...a11yProps(1)} />
                </Tabs>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

import React, { useState } from "react";
import Routes from "./routes/Routes";
import { Container, Grid } from "@mui/material";
import AppContainer from "./context-containers/AppContainer";
import UserContainer from "./context-containers/UserContainer";
import { withStyles } from "@mui/styles";
import BottomNavbar from "./pages/menus/BottomNavbar";
import BotnavContainer from "./context-containers/BotnavContainer";

function Main({ classes }) {
  return (
    <Container maxWidth="md">
    <UserContainer>
      <AppContainer>
        <BotnavContainer>
          <Grid container direction="column" justifyContent="space-evenly" >
            <Grid item lg={12} className="mainContentContainer">
              <Routes />
            </Grid>
            {/* <Grid item md={12}>
              <BottomNavbar />
            </Grid> */}
          </Grid>
        </BotnavContainer>
      </AppContainer>
    </UserContainer>
    </Container>
  );
}

export default withStyles({ withTheme: true })(Main);

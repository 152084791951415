import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Skeleton } from "@mui/material";
import MAINAPIS from "../helper/apiCalls";
import VendorListNav from "../app-bar/VendorListNav";
import VendorTabs from "./components/vendorlist/VendorTabs";
import CategoryDialogue from "./components/vendorlist/CategoryDialogue";

const VendorListPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [categoryDialogueOpen, setCategoryDialogueOpen] = useState(false);
  const [categoryID, setCategoryID] = useState(params.cat);
  const [categories, setCategories] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  // Effects
  useEffect(() => {
    async function fetchData() {
      const { data: categoryData = {} } = await getCategories();
      setCategories(categoryData);
    }
    fetchData();
  }, []);
  useEffect(() => {
    async function updateCat() {
      setSelectedCategory(
        categories && categories.find((cat) => cat.id === params.cat)
      );
    }
    updateCat();
  }, [categories]);
  // Fetching Category List to set State
  const getCategories = async () => {
    try {
      const result = await MAINAPIS.getCategories();
      // setIsLoading(true);
      return result;
    } catch (error) {
      return [];
    }
  };
  const handleCategoryDialogueOpen = () => {
    setCategoryDialogueOpen(true);
  };
  const handleClose = (value) => {
    setCategoryDialogueOpen(false);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <VendorListNav
            categoryClicked={selectedCategory}
            categoryDialogue={handleCategoryDialogueOpen}
            urlParams={params}
          />
        </Grid>
        <Grid item xs={12}>
          {selectedCategory ? (
            <VendorTabs
              categoryClicked={params.cat}
              category={selectedCategory}
            />
          ) : (
            <Skeleton
              variant="Rectangle"
              animation="wave"
              height="30px"
              width="100%"
              style={{ marginTop: "10px" }}
            />
          )}
        </Grid>
      </Grid>
      <CategoryDialogue
        open={categoryDialogueOpen}
        handleClose={handleClose}
        categories={categories}
        selectedCategory={selectedCategory}
        urlParams={params}
      />
    </React.Fragment>
  );
};

export default VendorListPage;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { Fab, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import MAINAPIS from "../helper/apiCalls";
// import JSEncrypt from "jsencrypt";

console.log('Hello World');
const Login = () => {
  // States
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(true);
  const [searchParams] = useSearchParams();
  const userContext = useContext(UserContext);
  let navigate = useNavigate();

  useEffect(() => {
    login();
  }, []);

  console.log(userContext.user);
  async function login() {
    const result = {status: true}
    //const { data: result = {} } = await loginApi(JSON.parse(uncrypted));

    if (result.status) {
      setSuccess(true);
      setLoading(false);
      // userContext.create(result.data);
      navigate("/");
    } else {
      setSuccess(false);
      setLoading(false);
    }
  }
  function loginApi(userData) {
    try {
      const result = MAINAPIS.loginUser(userData);
      return result;
    } catch (error) {
      return [];
    }
  }
  return (
    <React.Fragment>
      {loading && (
        <React.Fragment>
          <Grid container direction="column" className="loginSpinnerContainer">
            <Grid item xs={2}>
              <CircularProgress
                size={68}
                sx={{
                  zIndex: 1,
                  m: "1em",
                }}
                //onClick={handleButtonClick}
              />
            </Grid>
            <Grid item xs={2}>
              Logging In
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      {!loading && (
        <React.Fragment>
          <Grid container direction="column" className="loginSpinnerContainer">
            <Grid item xs={2}>
              <Fab
                sx={{ m: "1em" }}
                aria-label="save"
                color={success ? "success" : "error"}
                //onClick={handleButtonClick}
              >
                {success ? <CheckIcon /> : <CloseIcon />}
              </Fab>
            </Grid>
            <Grid item xs={2}>
              {success ? (
                <p> Login Successful, Routing to Application</p>
              ) : (
                <p> Login Failed, Please Try Again</p>
              )}
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Login;

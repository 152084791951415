import React, { useContext, useEffect, useState } from "react";

import { AppContext } from "../contexts/AppContext";

import { FormControl, MenuItem, Select } from "@mui/material";
import MAINAPIS from "../helper/apiCalls";

const CitySelect = () => {
  const context = useContext(AppContext);
  const defaultCities = [
    {
      city_ID: 1,
      name: "karachi"
    },
    {
      city_ID: 2,
      name: "lahore"
    },
    {
      city_ID: 3,
      name: "islamabad"
    },
  ];
  const [cities, setCities] = useState(defaultCities);
  // Fetching Cities to set State
  const getCities = async () => {
    try {
      const result = await MAINAPIS.getCities();
      setCities(result.data);
      console.log("citiesState", cities);
    } catch (error) {
      // return setCities([]);
    }
  };
  useEffect(() => {
    getCities();
  }, []);

  const handleCityChange = (event) => {
    context.setSelectedCity(event.target.value);
  };
  return (
    <FormControl sx={{ m: 1, minWidth: 150 }}>
      <Select
        value={context.selectedCity}
        onChange={handleCityChange}
        inputProps={{ "aria-label": "Without label" }}
      >
        {cities.map((city, i) => (
          <MenuItem value={city.name} key={city.name}>
            {city.name.charAt(0).toUpperCase() + city.name.slice(1)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CitySelect;

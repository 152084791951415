import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/Login";
import SecondPage from "../pages/home/SecondPage";
import VendorListPage from "../pages/VendorListPage";
import VendorDetailsPage from "../pages/VendorDetailsPage";

export const routes = [

  {
    path: "/login/",
    name: "Login",
    component: <LoginPage />,
    icon: <HomeIcon />,
  },
  {
    path: "/",
    name: "Home",
    component: <HomePage />,
    icon: <HomeIcon />,
  },
  {
    path: "/second-page",
    name: "Second Page",
    component: <SecondPage />,
    icon: <HomeIcon />,
  },
  {
    path: "/second-page/:id",
    name: "Second Page",
    component: <SecondPage />,
    icon: <HomeIcon />,
  },
  {
    path: "/vendorlist/:cat/:user/:city",
    name: "Vendor List",
    component: <VendorListPage />,
    icon: <HomeIcon />,
  },
  {
    path: "/vendordetails/:id/:user",
    name: "Vendor Details",
    component: <VendorDetailsPage />,
    icon: <HomeIcon />,
  },
];

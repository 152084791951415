import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel"; //https://www.npmjs.com/package/react-responsive-carousel

import { Paper, Grid } from "@mui/material";
import Savings from "@mui/icons-material/Savings";
import Call from "@mui/icons-material/Call";
import MailOutline from "@mui/icons-material/MailOutline";
import savingsIcon from "../../../Savingicon_96px.png";

export default class TopCarousel extends Component {
  render() {
    const slideData = this.props.slide;
    const { top = [], data = [] } = slideData;

    return (
      <Carousel
        autoPlay={false}
        emulateTouch={true}
        showArrows={true}
        showIndicators={false}
        swipeScrollTolerance={50}
        showThumbs={false}
        showStatus={false}
        className="demo-carousel"
      >
        {top.map((slide, i) => (
          <TopSlideModel key={i} slide={slide} />
        ))}
        <div>
          <Grid
            container
            direction="column"
            rowSpacing={2}
            flexBasis={2}
            alignItems="stretch"
            justifyContent="space-around"
            wrap="nowrap"
          >
            <Grid item xs={12} pb={2}>
              <Paper>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <Grid item xs={9}>
                    <h3>{data.savings}</h3>
                    <p>My Savings</p>
                  </Grid>
                  <Grid item xs={2}>
                    <img
                      style={{ width: "75%" }}
                      src={savingsIcon}
                      alt="savings icon"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Paper>
              <Grid container alignItems="center" justifyContent="space-around">
                <Grid item xs={9}>
                  <h3>{data.redCount}</h3>
                  <p>Vouchers Redeemed</p>
                </Grid>
                <Grid item xs={2}>
                  <img
                    style={{ width: "75%" }}
                    src={savingsIcon}
                    alt="savings icon"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </div>
      </Carousel>
    );
  }
}
function TopSlideModel(props) {
  var placeCall = (number) => {
    window.open(`tel:${number}`, "_self");
  };
  var sendMail = (email) => {
    window.open(`mailto:${email}`, "_self");
  };
  const bottomIconBarCSS = {
    position: "absolute",
    bottom: "-11px",
    left: 0,
    marginLeft: 0,
    width: "100%",
    background: "rgb(0 0 0 / 30%)",
    color: "#fff",
    padding: "26px",
    fontSize: "12px",
    textAlign: "center",
  };
  const bottomBarIconSize = { fontSize: 50, mx: 1, color: "white" };
  const customCSS = {
    backgroundColor: "black",
    color: "white",
    PointerEvent: "none",
    // minHeight: "30vh"
  };
  return (
    <div style={customCSS}>
      <img
        draggable="false"
        src={`https://hbl2server.vouch365.xyz/${props.slide.header}`}
        alt=""
      />
      <div style={bottomIconBarCSS}>
        <Link to="/">
          <Call
            sx={bottomBarIconSize}
            onClick={() => placeCall("021111486824")}
          />
        </Link>
        <Link to="/">
          <MailOutline
            sx={bottomBarIconSize}
            onClick={() => sendMail("info@entertainerasia.com")}
          />
        </Link>
      </div>
    </div>
  );
}

import React from "react";
import { Grid, Paper, Skeleton } from "@mui/material";

const VendorListElementSkeleton = () => {
  return (
    <Paper className="vendorListElementContainer">
      <Grid container>
        <Grid item xs={3} className="vendorListElementLogo">
          <Skeleton
            variant="Square"
            animation="wave"
            height="6rem"
            width="6rem"
          />
        </Grid>
        <Grid item xs={9} className="vendorListElementMText">
          <Grid
            container
            className="vendorListElementMGrid"
            direction={"column"}
            justifyContent={"space-around"}
            alignItems={"stretch"}
          >
            <Grid item>
              <Skeleton variant="Rectangle" animation="wave" height="2rem" />
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item xs={1}>
                  <Skeleton
                    variant="Rectangle"
                    animation="wave"
                    width="25px"
                    height="1.5rem"
                  />
                </Grid>
                <Grid item xs={11} className="vendorListElementLocality">
                  <Skeleton
                    variant="Rectangle"
                    animation="wave"
                    height="1.5rem"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default VendorListElementSkeleton;

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Paper, Grid } from "@mui/material";
import { AppContext } from "../../../contexts/AppContext";

const CategoryBar = ({ categories, userId }) => {
  return (
    <Paper elevation={6}>
      <Grid container className="categoryContainer">
        {categories.map((category, i) => (
          <Category key={i} category={category} userId={userId} />
        ))}
      </Grid>
    </Paper>
  );
};
function Category(props) {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  function onClick() {
    context.setSelectedCategory(props.category.name);
    navigate(`/vendorlist/${props.category.id}/${props.userId}/${context.selectedCity}`);
  }

  return (
    <Grid
      item
      container
      xs={2}
      direction="column"
      className="categoryItem"
      onClick={onClick}
    >
      <Grid item md={12}>
        <img
          draggable="false"
          src={props.category.retail}
          alt={props.category.name}
          width={"50px"}
        />
      </Grid>

      <Grid item md={12}>
        <p>
          {" "}
          <small>{props.category.name}</small>{" "}
        </p>
      </Grid>
    </Grid>
  );
}

export default CategoryBar;

import React, { useEffect, useState } from "react";
import { Grid, Paper } from "@mui/material";
import LocationData from "./LocationData";
import VoucherListElement from "./VoucherListElement";
import VoucherHeading from "../vendordetails/VoucherHeading";
import VoucherDialogue from "../voucher/VoucherDialogue";
import { Image } from "react-image-and-background-image-fade";
// Buttons
import Call from "@mui/icons-material/Call";
import CallDialogue from "./CallDialogue";
import LocationDialogue from "./LocationDialogue";

const OffersTab = ({
  vendorID,
  vendorDetails,
  selectedLocation,
  reloadMethod,
  switchLocation,
  dialogueOpenCounter,
  setDialogueOpenCounter,
}) => {
  // States
  const [voucherDialogueOpen, setVoucherDialogueOpen] = React.useState(false);
  const [callDialogueOpen, setCallDialogueOpen] = useState(false);
  const [locationDialogueOpen, setLocationDialogueOpen] = useState(false);
  const [voucher, setVoucher] = useState({});
  const vouchers = vendorDetails.vouchersData;
  const category = vendorDetails.categoryData ? vendorDetails.categoryData : {};
  const locations = vendorDetails.locationData
    ? vendorDetails.locationData
    : [];
  const handleClickOpen = () => {
    setVoucherDialogueOpen(true);
  };
  const handleClose = (value) => {
    setVoucherDialogueOpen(false);
    reloadMethod();
  };

  const handleCallDialogueOpen = () => {
    setCallDialogueOpen(true);
  };
  const handleCallDialogueClose = (value) => {
    setCallDialogueOpen(false);
  };
  const handleLocationDialogueOpen = () => {
    if (locations.length > 1) setLocationDialogueOpen(true);
  };
  const handleLocationDialogueClose = (value) => {
    setLocationDialogueOpen(false);
  };
  useEffect(() => {
    if (locations.length > 1 && dialogueOpenCounter === 0) {
      setLocationDialogueOpen(true);
      setDialogueOpenCounter(dialogueOpenCounter + 1);
    }
  }, [locations]);

  console.log(vendorDetails);
  return (
    <React.Fragment>
      <Grid container>
        <Grid item className="imagesContainer" xs={12}>
          <Image
            src={vendorDetails.header ? vendorDetails.header : ""}
            width="100%"
            height="60%"
            alt={vendorDetails.name}
            title={vendorDetails.name}
            className="reactLazyLoaderBGimageSetter"
            isResponsive
            lazyLoad
          />

          <img
            src={vendorDetails.logo}
            alt=""
            className="logoImage border-radius-10"
          />
          <Call className="callButton" onClick={handleCallDialogueOpen} />
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={1}
            className="locationDataContainer"
            onClick={handleLocationDialogueOpen}
          >
            <LocationData
              selectedLocation={selectedLocation}
              vendorID={vendorID}
              xs={12}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <VoucherHeading
            text="Offers Contained in this Product"
            subtext={vendorDetails.city + " " + vendorDetails.Year}
          />
        </Grid>
        <Grid item xs={12}>
          {vouchers &&
            vouchers.map((voucher, i) => (
              <VoucherListElement
                key={voucher.vc_ID}
                voucher={voucher}
                openDialogue={handleClickOpen}
                setVoucher={setVoucher}
                category={category}
                vendorName={vendorDetails.name}
              />
            ))}
        </Grid>
        <VoucherDialogue
          open={voucherDialogueOpen}
          handleClose={handleClose}
          voucher={voucher}
          logo={vendorDetails.logo}
          category={category}
          vendorName={vendorDetails.name}
          vendorId={selectedLocation}
          userData={vendorDetails.userData}
        />
      </Grid>

      <CallDialogue
        open={callDialogueOpen}
        handleClose={handleCallDialogueClose}
        vendorName={vendorDetails.name}
        vendorNumber={vendorDetails.number}
      />
      <LocationDialogue
        open={locationDialogueOpen}
        handleClose={handleLocationDialogueClose}
        vendorName={vendorDetails.name}
        locationData={locations}
        selectedLocation={selectedLocation}
        switchLocation={switchLocation}
      />
    </React.Fragment>
  );
};

export default OffersTab;

import React from "react"
import { styled, alpha } from "@mui/material/styles"
import InputBase from "@mui/material/InputBase"
import SearchIcon from "@mui/icons-material/Search"
import { useNavigate } from "react-router-dom"

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    //   borderRadius: theme.shape.borderRadius,
    border: "1px rgba(0, 0, 0, 0.15) solid",
    color: "black",
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.black, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("xs")]: {
        marginLeft: theme.spacing(0),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: "auto"
    }
}))

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("xs")]: {
            width: "30ch",
            "&:focus": {
                width: "30ch"
            }
        }
    }
}))

function SearchBar({ onSearch }) {

    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder="Search Vendor…"
                inputProps={{ "aria-label": "search" }}
                onKeyUp={onSearch}
            />
        </Search>
    )
}

export default SearchBar
